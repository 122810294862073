.avatar {
    margin-left: 1rem;
    margin-right: 1rem; 
    background-color: rgb(253, 225, 189);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.avatar img {
    width: auto;
    height: 50%;
    transform: scale(1.2);
    transition: transform 0.3s ease-in-out;
}

.avatar:hover img {
    transform: scale(1.3);
}