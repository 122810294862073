.offeringCard {
    width: 200px;
}

.offeringCard img{
    width: 200px;
    border-radius: 16px;
}

.offeringCard .title {
    font-size: 20px;
    margin-top: 5px;
}

.offeringCard .content {
    font-size: 14px;
    text-align: justify;
    text-justify: inter-word;
    hyphens: auto;
}

@media (max-width: 515px) {
    .offeringCard .title {
        text-align: center;
    }
    
    .offeringCard .content {
        font-size: 14px;
        text-align: center;
    }
}