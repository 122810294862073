.contact {
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 5rem;
    max-width: 1000px;
}

.contact .title {
    font-size: 24px;
    font-weight: bold;
}

.contact .contactCard {
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
}

.infoText {
    padding: 1rem;
    background-color: rgba(253, 225, 189, 0.5);
    border-radius: 16px;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.infoText.success {
    background-color: lightgreen;
}

.infoText.error {
    background-color: lightcoral;
}

.shortFields {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    width: 100%;
}

.shortFiels > div {
    flex: 1;
}

.MuiFormControl-root {
    width: 100%;
}

.contactForm {
    display: flex;
    flex-direction: column;
}

.submitButton {
    margin-top: 10px;
    padding: 0.5rem;
    align-self: flex-end;
    background-color: rgb(253, 225, 189);
}

@media (max-width: 600px) {
    .infoText {
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }

    .shortFields {
        flex-direction: column;
        gap: 0;
    }
}
