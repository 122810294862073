.title-image-wrapper {
    background-image: url('../images/title-image.png');
    background-position: top center;
    background-size: cover;
    width: 80%;
    height: 500px;
    border-radius: 24px;
    margin-top: 1rem;
    color: white;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.title-text-wrapper {
    background-color: rgba(0, 0, 0, 0.4); /* Use rgba for opacity */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    text-align: center;
}

.title-text-wrapper h1 {
    font-size: 42px;
    margin: 0;
    padding: 0 20px;
}

.title-text-wrapper h3 {
    margin: 20px 5rem;
    padding: 0 20px;
}

@media (max-width: 940px) {
    .title-text-wrapper h1 {
        font-size: 30px;
    }
    .title-text-wrapper h3 {
        margin: 20px;
    }
}

@media (max-width: 480px) {
    .title-image-wrapper {
        height: 300px;
    }

    .title-text-wrapper h1 {
        font-size: 24px;
    }
    .title-text-wrapper h3 {
        margin: 10px;
        font-size: 14px;
    }
}
