.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactBox {
    display: flex;
    width: 80%;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    justify-content: space-between;
    gap: 2rem;
}