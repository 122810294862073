.offering {
    width: 80%;
    text-align: start;
    margin-top: 2rem;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.offering-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 1100px) {
    .offering-cards {
        gap: 5rem;
    }
}

@media (max-width: 949px) {
    .offering {
        text-align: center;
    }

    .offering-cards {
        gap: 1rem;
        justify-content: space-around;
        text-align: start;
    }
}