.priceCard {
    text-align: start;
    width: 80%;
    margin-top: 2rem;
    max-width: 1000px;
}

.priceCard .title {
    font-size: 24px;
    font-weight: bold;
}

.priceCardContent {
    display: flex;
    flex-direction: column;
    background-color: rgb(253, 225, 189);
    border-radius: 16px;
    border: 1px solid rgb(253, 225, 189);
    box-shadow: 1px 1px 1px rgb(0, 0, 0. 0.4);
    padding: 1rem;
}

.priceCardContent .checkBoxes {
    display: flex;
    flex-direction: column;
}

.price {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.price .priceValue {
    font-size: 24px;
    font-weight: bold;
    align-self: flex-end;
    border-bottom: 1px solid black;
}


@media (max-width: 775px) {
    .price .priceValue {
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .price {
        flex-direction: column;
    }

    .price .contact {
        width: 100%;
        text-align: center;
        font-size: 16px;
    }

    .priceCard {
        flex-direction: row;
    }

    .price .priceValue {
        margin-top: 1rem;
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .priceCardContent .checkBoxes {
        gap: 1rem;
    }
}